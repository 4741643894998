<template>
  <div class="whitelistAnalysis mt-20">
    <!-- 白名单总统计信息 -->
    <div class="whitelist-info mb-20">
      <ul class="push-user-info">
        <li class="li_1">
          <p class="data" v-text="basicInfo.sumOpenEmailCount || 0" />
          <p class="text">邮件阅读次数</p>
        </li>
        <li class="li_2">
          <p class="data" v-text="basicInfo.countOpenEmailAuthor || 0" />
          <p class="text">邮件阅读人数</p>
        </li>
        <li class="li_3">
          <p class="data" v-text="basicInfo.sumReadCount || 0" />
          <p class="text">文章阅读次数</p>
        </li>
        <li class="li_4">
          <p class="data" v-text="basicInfo.countReadAuthor || 0" />
          <p class="text">文章阅读人数</p>
        </li>
      </ul>
    </div>
    <!-- 白名单用户活跃排行 -->
    <div class="active-whitelist-users mb-20">
      <div class="second-title table-title">
        <span>白名单用户活跃排行</span>
      </div>
      <commonTable
        :table-data="tableData"
        :column-data="tableColumn"
        :init-obj="tablePageObj"
        @onChangeSort="
          (prop, order) => {
            handleChangeSort(prop, order)
          }
        "
        @onChangePageData="
          (pageObj) => {
            getTableData(pageObj)
          }
        "
      />
    </div>
  </div>
</template>

<script>
import { Get, ProjectConfig } from 'kjqk-commons/src/common/index.js'
import commonTable from '@comp/commonTable'
const api = ProjectConfig.emailPush
export default {
  name: 'WhitelistAnalysis',
  components: {
    commonTable
  },
  data() {
    return {
      taskId: '',
      basicInfo: {},
      tableData: [],
      tableColumn: [
        {
          title: '姓名',
          prop: 'name',
          notSortable: true
        },
        {
          title: '机构',
          prop: 'orgNames',
          notSortable: true
        },
        {
          title: '阅读邮件次数',
          prop: 'sumOpenEmailCount'
        },
        {
          title: '阅读文章次数',
          prop: 'sumReadCount'
        }
      ],
      tablePageObj: {
        page: 1,
        size: 10,
        sort: ''
      }
    }
  },
  methods: {
    getData(item) {
      this.taskId = item.id
      Promise.all([this.getSumWhiteListAuthor(), this.getTableData()]).then(
        () => {
          this.$emit('changeScroll')
        }
      )
    },
    // 白名单总统计信息
    getSumWhiteListAuthor() {
      const params = {
        taskId: this.taskId
      }
      let url = api.GetSumWhiteListAuthor.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            this.basicInfo = data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 获取白名单用户活跃排行table数据
    getTableData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        taskId: this.taskId,
        page: page - 1,
        size,
        sort: this.tablePageObj.sort
      }
      let url = api.GetWhiteListAuthorSumList.url
      return new Promise((resolve, reject) => {
        Get(url, { params }).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            const { content = [], totalElements = 0 } = data
            this.tablePageObj = {
              ...this.tablePageObj,
              page,
              size,
              total: totalElements
            }
            this.tableData = content
            resolve()
          } else {
            reject()
            // const { message } = res.data;
            // this.$message.error(message);
          }
        })
      })
    },
    // 白名单用户活跃排行table排序改变
    handleChangeSort(prop, order) {
      let sortValue = ''
      if (!order || order === '') {
        sortValue = ''
      } else {
        if (order === 'ascending') {
          sortValue = prop + ',asc'
        } else {
          sortValue = prop + ',desc'
        }
      }
      this.tablePageObj.sort = sortValue
      this.getTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
.whitelistAnalysis {
  .whitelist-info {
    .push-user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        width: 23%;
        height: 41px;
        padding: 15px 0;
        text-align: center;
        .data {
          font-size: 18px;
          font-family: FZYOUHS_508R--GB1-0;
        }
        .text {
          color: #6c6c6c;
          font-size: 10px;
        }
      }
      .li_1 {
        background: #e6f4eb;
        color: #39b54a;
      }
      .li_2 {
        background: #e1f3f9;
        color: #29abe2;
      }
      .li_3 {
        background: #f5f7fd;
        color: #ff00ff;
      }
      .li_4 {
        background: #f7f3f0;
        color: #f7931e;
      }
    }
  }
}
</style>
