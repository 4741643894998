var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pushEffectAnalysis mt-20"},[_c('div',{staticClass:"push-progress"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("推送进度")]),_c('div',{staticClass:"progress-text"},[_vm._v("推送状态："+_vm._s(_vm.progressText))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWZYDL),expression:"showWZYDL"}],staticClass:"article-reading mb-20"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("文章阅读量排行")]),_c('commonTable',{attrs:{"table-data":_vm.articleTableData,"column-data":_vm.articleColumn,"init-obj":_vm.articlePageObj},on:{"onChangePageData":(pageObj) => {
          _vm.getArticleListData(pageObj)
        }}})],1),(_vm.showWZYHJG || _vm.showWZYHQK)?_c('div',{staticClass:"reading-article-user-distribution mb-20"},[_c('div',{staticClass:"first-title"},[_vm._v("阅读文章用户分布")]),(_vm.showWZYHJG)?_c('div',[_c('div',{staticClass:"echarts-title"},[_vm._m(0),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.WZYHJGActive === 10 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-阅读文章用户的机构分布-Top10"},on:{"click":function($event){_vm.WZYHJGActive = 10}}},[_vm._v(" Top10 ")]),_c('div',{staticClass:"mr-8",class:_vm.WZYHJGActive === 15 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-阅读文章用户的机构分布-Top15"},on:{"click":function($event){_vm.WZYHJGActive = 15}}},[_vm._v(" Top15 ")]),_c('div',{class:_vm.WZYHJGActive === 20 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-阅读文章用户的机构分布-Top20"},on:{"click":function($event){_vm.WZYHJGActive = 20}}},[_vm._v(" Top20 ")])])]),_c('div',{style:({
          width: '100%',
          height: '420px'
        }),attrs:{"id":"chartWZYHJG"}})]):_vm._e(),(_vm.showWZYHQK)?_c('div',[_c('div',{staticClass:"echarts-title mt-20"},[_c('div',{staticClass:"second-title"},[_c('span',[_vm._v("阅读文章用户的期刊分布 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"阅读文章用户的期刊分布：截至当前时间，阅读文章的用户，曾发表过文章的期刊的分布","placement":"top"}},[_c('i',{staticClass:"el-icon-question"})])],1)]),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.WZYHQKActive === 10 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-阅读文章用户的期刊分布-Top10"},on:{"click":function($event){_vm.WZYHQKActive = 10}}},[_vm._v(" Top10 ")]),_c('div',{staticClass:"mr-8",class:_vm.WZYHQKActive === 15 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-阅读文章用户的期刊分布-Top15"},on:{"click":function($event){_vm.WZYHQKActive = 15}}},[_vm._v(" Top15 ")]),_c('div',{class:_vm.WZYHQKActive === 20 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-阅读文章用户的期刊分布-Top20"},on:{"click":function($event){_vm.WZYHQKActive = 20}}},[_vm._v(" Top20 ")])])]),_c('div',{style:({
          width: '100%',
          height: '420px'
        }),attrs:{"id":"chartWZYHQK"}})]):_vm._e()]):_vm._e(),_c('div',{staticClass:"mb-20 echarts-title"},[_c('div',{staticClass:"first-title mb-20"},[_vm._v("用户活跃度排行")]),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.userActivity === 50 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-用户活跃度排行-Top50"},on:{"click":function($event){_vm.userActivity = 50}}},[_vm._v(" Top50 ")]),_c('div',{class:_vm.userActivity === 'author' ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送结果-用户活跃度排行-本刊作者"},on:{"click":function($event){_vm.userActivity = 'author'}}},[_vm._v(" 本刊作者 ")])]),_c('commonTable',{attrs:{"table-data":_vm.authorTableData,"column-data":_vm.authorColumn,"init-obj":_vm.authorPageObj},on:{"onChangePageData":(pageObj) => {
          _vm.getAuthorListData(pageObj)
        },"onChangeSort":(prop, order) => {
          _vm.handleChangeSort(prop, order)
        }}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("阅读文章用户的机构分布")])])
}]

export { render, staticRenderFns }