var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pushUserData mt-20"},[_c('div',{staticClass:"push-user-profile mb-20"},[_vm._m(0),_c('ul',{staticClass:"push-user-info"},[_c('li',{staticClass:"li_1"},[_c('p',{staticClass:"data",domProps:{"textContent":_vm._s(_vm.basicInfo.countOrg || 0)}}),_c('p',{staticClass:"text"},[_vm._v("机构覆盖量")])]),_c('li',{staticClass:"li_2"},[_c('p',{staticClass:"data",domProps:{"textContent":_vm._s(_vm.basicInfo.countMag || 0)}}),_c('p',{staticClass:"text"},[_vm._v("期刊覆盖量")])]),_c('li',{staticClass:"li_3"},[_c('p',{staticClass:"data",domProps:{"textContent":_vm._s(_vm.basicInfo.countWhiteListAuthor || 0)}}),_c('p',{staticClass:"text"},[_vm._v("白名单用户人数")])])])]),(_vm.showTSYHLY)?_c('div',{staticClass:"push-user-source mb-20"},[_vm._m(1),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartTSYHLY"}})]):_vm._e(),(_vm.showTSYHJG)?_c('div',{staticClass:"push-user-mechanism mb-20"},[_c('div',{staticClass:"echarts-title"},[_vm._m(2),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.TSYHJGActive === 10 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送用户概况-推送用户的机构分布-Top10"},on:{"click":function($event){_vm.TSYHJGActive = 10}}},[_vm._v(" Top10 ")]),_c('div',{staticClass:"mr-8",class:_vm.TSYHJGActive === 15 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送用户概况-推送用户的机构分布-Top15"},on:{"click":function($event){_vm.TSYHJGActive = 15}}},[_vm._v(" Top15 ")]),_c('div',{class:_vm.TSYHJGActive === 20 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送用户概况-推送用户的机构分布-Top20"},on:{"click":function($event){_vm.TSYHJGActive = 20}}},[_vm._v(" Top20 ")])])]),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartTSYHJG"}})]):_vm._e(),(_vm.showTSYHQK)?_c('div',{staticClass:"push-user-periodical mb-20"},[_c('div',{staticClass:"echarts-title"},[_c('div',{staticClass:"second-title"},[_c('span',[_vm._v("推送用户的期刊分布 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"推送用户的期刊分布：推送用户曾发表过文章的期刊的分布","placement":"top"}},[_c('i',{staticClass:"el-icon-question"})])],1)]),_c('div',{staticClass:"top"},[_c('div',{staticClass:"mr-8",class:_vm.TSYHQKActive === 10 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送用户概况-推送用户的期刊分布-Top10"},on:{"click":function($event){_vm.TSYHQKActive = 10}}},[_vm._v(" Top10 ")]),_c('div',{staticClass:"mr-8",class:_vm.TSYHQKActive === 15 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送用户概况-推送用户的期刊分布-Top15"},on:{"click":function($event){_vm.TSYHQKActive = 15}}},[_vm._v(" Top15 ")]),_c('div',{class:_vm.TSYHQKActive === 20 ? 'active' : '',attrs:{"data-track":"增值服务-主动传播-邮件传播-推送用户概况-推送用户的期刊分布-Top20"},on:{"click":function($event){_vm.TSYHQKActive = 20}}},[_vm._v(" Top20 ")])])]),_c('div',{style:({
        width: '100%',
        height: '420px'
      }),attrs:{"id":"chartTSYHQK"}})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("推送用户概况")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("推送用户的来源分布")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"second-title"},[_c('span',[_vm._v("推送用户的机构分布")])])
}]

export { render, staticRenderFns }